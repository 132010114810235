import BatchReportData from '../BatchReportData';
import WellType from '../WellType';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Specimen from '../Specimen';
import React, { useEffect, useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { AppModeConfig } from '../../AppModes/AppModeConfig';
import { AppModes } from '../Constants/AppModes';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { ModalStyle, SectionWrapper } from './styles';
import WarningIcon from '@mui/icons-material/Warning';

const TableHeaders = [
    "Well Number",
    "",
    "",
    "Person Name",
    "Person DOB",
    "",
    "Comments"
];

interface PanelDataTableProps {
    data: BatchReportData[],
    isEditable?: boolean,
    onEditSpecimenComment?: (specimen: Specimen) => void,
    onReportSpecimenError?: (specimen: Specimen, sampleErrorNote: string) => void,
    appModeConfig: AppModeConfig
}

const PanelDataTable = ({ data, onEditSpecimenComment, onReportSpecimenError, isEditable = false, appModeConfig }: PanelDataTableProps) => {

    const [rows, setRows] = useState<JSX.Element[]>([]);
    const [selectedSpecimen, setSelectedSpecimen] = useState<Specimen>();
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [confirmationTextElement, setConfirmationTextElement] = useState<JSX.Element>(<></>);
    const [patientSampleErrorNote, setPatientSampleErrorNote] = useState<string>("");

    const generateRows = (data: BatchReportData[]) => data.map((reportData, index) => {

        const onSubmitSpecimenCommentKeyUp = (event: any) => {
            if (event.key === "Enter") {
                var comment = event.target.value;

                var specimen: Specimen = {...reportData.specimen!, comment: comment};

                if (onEditSpecimenComment) onEditSpecimenComment(specimen);
            }
        }

        const onReportSpecimenErrorClick = () => {
            var confirmationTextElement = <p>Are you sure you want to report this sample as an error?</p>;

            setConfirmationTextElement(confirmationTextElement)
            setOpenConfirmation(true);
            setSelectedSpecimen(reportData.specimen!);
            setPatientSampleErrorNote(reportData.patient!.sampleErrorNote);
        };
    
        // Column header at index 1 is app mode specific
        TableHeaders[1] = evaluateAppModeHeader(appModeConfig);

        // Column header at index 2 is app mode specific
        TableHeaders[2] = evaluateAppModeBarcodeHeader(appModeConfig);

        // Column header at index 5 is app mode specific
        TableHeaders[5] = evaluateAppModeErrorHeader(appModeConfig);

        switch(reportData.wellType) {
            case WellType.STANDARD:
                return (
                    <TableRow
                        key={`row-${index}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">{reportData.wellNumber}</TableCell>
                        <TableCell>{reportData.specimen ? reportData.specimen.caseId : reportData.patient?.sampleId}</TableCell>
                        {appModeConfig.name === AppModes.CARRIERSCREENING && reportData.patient?.sampleId !== "" &&
                        <TableCell>{reportData.patient?.sampleId}</TableCell>}
                        <TableCell>{reportData.specimen ? `${reportData.specimen!.lastName.toUpperCase()}, ${reportData.specimen!.firstName.toUpperCase()}` : `${reportData.patient!.lastName.toUpperCase()}, ${reportData.patient!.firstName.toUpperCase()}`}</TableCell>
                        <TableCell>{reportData.specimen ? reportData.specimen!.dateOfBirth.toString() : reportData.patient!.dateOfBirth.toString()}</TableCell>
                        {appModeConfig.name === AppModes.CARRIERSCREENING && reportData.patient!.sampleErrorNote !== "" &&
                        <TableCell><Button color="error" variant="contained" onClick={onReportSpecimenErrorClick}>Report Error</Button></TableCell>}
                        {appModeConfig.name === AppModes.CARRIERSCREENING && reportData.patient!.sampleErrorNote === "" &&
                        <TableCell><Button color="success" variant="contained" onClick={onReportSpecimenErrorClick}>Report Error</Button></TableCell>}
                        {appModeConfig.name !== AppModes.CARRIERSCREENING &&
                        <TableCell></TableCell>}
                        {isEditable &&
                        <TableCell width="30%">
                            <div key={reportData.specimen!.comment}>
                                <TextField 
                                    id={`panel-data-${index}-comment-input`}
                                    variant="standard" 
                                    onKeyUp={onSubmitSpecimenCommentKeyUp}
                                    defaultValue={reportData.specimen!.comment}
                                    fullWidth
                                />
                            </div>
                        </TableCell>}
                        {!isEditable && 
                        <TableCell sx={{width: '30%'}}> {reportData.specimen?.comment} </TableCell>}
                    </TableRow>
                );
            case WellType.POSITIVECONTROL:
            case WellType.NEGATIVECONTROL:
            case WellType.LADDER:
                return (
                <TableRow
                    key={`row-${index}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">{reportData.wellNumber}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>{reportData.wellType.toString()}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell sx={{width: '40%'}}></TableCell>
                </TableRow>);
            default:
                return <></>
        }
    });

    useEffect(() => {
        setRows([...generateRows(data)]);
    }, [data])


    const handleSampleError = () => {
        setOpenConfirmation(false);

        if (onReportSpecimenError) onReportSpecimenError(selectedSpecimen!, patientSampleErrorNote);
    }

    const toggleModal = () => {
        setOpenConfirmation(openConfirmation => !openConfirmation);
    }

    const handlePatientSampleErrorNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPatientSampleErrorNote(event.target.value);
    };

    return (
        <>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                <TableRow>
                    {TableHeaders.map((header, index) => <TableCell key={`header-${index}`}>{header}</TableCell>)}
                </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>
        <Modal
        open={openConfirmation}
        onClose={() => toggleModal()}
        >
            <Box sx={ModalStyle}>
                <WarningIcon />
                <Typography variant="h6" component="h2">
                    {confirmationTextElement}
                </Typography>
                <Box sx={{ minWidth: 120 }}>
                    <SectionWrapper>
                        <Typography gutterBottom variant="h6" component="span">
                        Note:  {" "}
                        </Typography>
                        <TextField 
                            id="sampleerrornote" 
                            label="" 
                            variant="standard" 
                            value={patientSampleErrorNote ?? ""}
                            onChange={handlePatientSampleErrorNoteChange}
                        />
                    </SectionWrapper>
                </Box>
                <Button color="success" variant="contained" onClick={handleSampleError} sx={{margin: '0 2rem'}}>SUBMIT</Button>
                <Button color="error" variant="contained" onClick={toggleModal}>CANCEL</Button>
            </Box>
        </Modal>
        </>
    );
};

const evaluateAppModeHeader = (appModeConfig: AppModeConfig) => {
    switch (appModeConfig.name) {
        case AppModes.KINSHIP:
            return "Case ID";
        case AppModes.CARRIERSCREENING:
            return "Product";
        default:
            console.error("Invalid app mode: " + appModeConfig.name);
            return "";
    }
}

const evaluateAppModeBarcodeHeader = (appModeConfig: AppModeConfig) => {
    switch (appModeConfig.name) {
        case AppModes.KINSHIP:
            return "";
        case AppModes.CARRIERSCREENING:
            return "Barcode";
        default:
            console.error("Invalid app mode: " + appModeConfig.name);
            return "";
    }
}

const evaluateAppModeErrorHeader = (appModeConfig: AppModeConfig) => {
    switch (appModeConfig.name) {
        case AppModes.KINSHIP:
            return "";
        case AppModes.CARRIERSCREENING:
            return "Error";
        default:
            console.error("Invalid app mode: " + appModeConfig.name);
            return "";
    }
}

export default PanelDataTable;